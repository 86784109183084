import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { nutritionCategories } from "../../Components/NutritionTable/consts/nutritionconsts";

export const NutritionContext = createContext({});
export const useNutritionContext = () => useContext(NutritionContext);

// const serverURL = "http://localhost:8000";
const serverURL = "https://kobus.degraafjes.net";

export const NutritionProvider = ({ children, category }) => {
  const initialState = {};
  nutritionCategories.forEach((entry) => (initialState[entry] = {}));
  const [nutrition, setNutrition] = useState(initialState);
  const [loading, setLoading] = useState(true);

  async function getNutrients(category) {
    const sessionToken = getSessionToken();
    await axios
      .get(serverURL + `/index.php/nutrients/list?category=${category}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionToken,
        },
      })
      .then((response) => {
        console.log("Nutrients Received!");
        console.log(response);
        setNutrition(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function addNutrient(category, nutrient) {
    try {
      const response = await axios.post(
        serverURL + `/index.php/nutrients/edit?category=${category}`,
        { nut: JSON.stringify(nutrient) },
      );
    } catch (error) {
      console.error("error-respons", error);
    }
  }

  async function updateNutrient(category, nutrient) {
    try {
      const response = await axios.patch(
        serverURL + `/index.php/nutrients/edit?category=${category}`,
        { nut: JSON.stringify(nutrient) },
      );
    } catch (error) {
      console.error("error-respons", error);
    }
  }

  async function deleteNutrient(category, id) {
    try {
      const response = await axios.patch(
        serverURL + `/index.php/nutrients/delete?category=${category}`,
        { id: id },
      );
    } catch (error) {
      console.error("error-respons", error);
    }
  }

  useEffect(() => {
    getNutrients(category);
  }, [category]);

  return (
    <NutritionContext.Provider
      value={{
        loading,
        nutrition,
        addNutrient,
        deleteNutrient,
        updateNutrient,
      }}
    >
      {children}
    </NutritionContext.Provider>
  );
};
