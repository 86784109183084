import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useWeatherContext } from "../../api/Weather/Weather";
import { ForecastTableHeaders } from "./consts/headers";
import useWindowDimensions from "../../Logic/WindowDimensions/WindowDimensions";

function convertToBeaufort(kph) {
  if (kph < 1) {
    return 0;
  } else if (kph < 5) {
    return 1;
  } else if (kph < 11) {
    return 2;
  } else if (kph < 19) {
    return 3;
  } else if (kph < 28) {
    return 4;
  } else if (kph < 38) {
    return 5;
  } else if (kph < 49) {
    return 6;
  } else if (kph < 61) {
    return 7;
  } else if (kph < 74) {
    return 8;
  } else if (kph < 88) {
    return 9;
  } else if (kph < 102) {
    return 10;
  } else if (kph < 117) {
    return 11;
  } else {
    return 12;
  }
}

export default function BasicTable() {
  const windowDims = useWindowDimensions();
  const hookCall = useWeatherContext();
  const weather = hookCall.loading ? null : hookCall.weather;
  if (hookCall.loading) {
    return <div className="spinner" />;
  } else {
    const rows = Array(weather.data.current).concat(
      weather.data.forecast.forecastday[0].hour.filter((item) => {
        if (
          item.time.split(" ")[1] >
          weather.data.location.localtime.split(" ")[1]
        )
          return item;
        else return null;
      }),
      weather.data.forecast.forecastday[1].hour,
    );
    rows[0]["time"] = weather.data.current.last_updated + " (H)";
    rows[0]["chance_of_rain"] = rows[0]["precip"] !== 0 ? "100" : "0";
    return (
      <TableContainer
        component={Paper}
        style={{
          position: "absolute",
          maxHeight: (windowDims.height - 64).toString() + "px",
        }}
      >
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          stickyHeader
          aria-label="a dense table"
        >
          <TableHead key="HEAD">
            <TableRow key="HEADROW">
              {ForecastTableHeaders.map((entry) => (
                <TableCell key={entry.id}>{entry.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((entry, i) => (
              <TableRow key={i}>
                <TableCell key={i + "time"}>{entry.time}</TableCell>
                <TableCell key={i + "icon"} style={{ padding: "0px" }}>
                  <img
                    src={entry.condition.icon}
                    width="40px"
                    height="40px"
                    alt=""
                  />
                </TableCell>
                <TableCell key={i + "temp_c"}>{entry.temp_c}°C</TableCell>
                <TableCell key={i + "feel"}>{entry.feelslike_c}°C</TableCell>
                <TableCell key={i + "hum"}>{entry.humidity}%</TableCell>
                <TableCell key={i + "rain"}>{entry.chance_of_rain}%</TableCell>
                <TableCell key={i + "precip"}>{entry.precip_mm} mm</TableCell>
                <TableCell key={i + "wind"}>
                  {convertToBeaufort(entry.wind_kph)}
                </TableCell>
                <TableCell key={i + "cloud"}>{entry.cloud}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
