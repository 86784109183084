import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Home from "./Routes/Home/Home";
import Logout from "./Routes/Logout/Logout";
import WeatherPage from "./Routes/Weather/Weather";
import VoedingPage from "./Routes/Voeding/Voeding";
import DiaryPage from "./Routes/Diary/Diary";
import SensorPage from "./Routes/Sensor/Sensor";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { registerLicense } from "@syncfusion/ej2-base";
import { AuthProvider } from "@descope/react-sdk";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/weather",
        element: <WeatherPage />,
      },
      {
        path: "/voeding",
        element: <VoedingPage />,
      },
      {
        path: "/dagboek",
        element: <DiaryPage />,
      },
      {
        path: "/sensor",
        element: <SensorPage />,
      },
    ],
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <ErrorPage />,
  },
]);

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfd3RQQ2leVU11XkU=",
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider projectId="P2jMvMuHLoulz6eoHZPA2PURyEEv">
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
