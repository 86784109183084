export const sensorColumns = [
  { id: 0, field: "date", headerName: "Datum" },
  {
    id: 1,
    field: "time",
    headerName: "Tijd",
  },
  {
    id: 2,
    field: "sensBatt",
    headerName: "Batterij",
  },
  {
    id: 3,
    field: "curAct",
    headerName: "Huidige activiteit",
  },
  {
    id: 4,
    field: "sensTemp",
    headerName: "Sensor Temp",
  },
  {
    id: 5,
    field: "sensHum",
    headerName: "Sensor Hum",
  },
  {
    id: 6,
    field: "steps",
    headerName: "Stappen",
  },
  {
    id: 7,
    field: "stillSecs",
    headerName: "Stil",
  },
  {
    id: 8,
    field: "walkingSecs",
    headerName: "Lopend",
  },
  {
    id: 9,
    field: "runningSecs",
    headerName: "Rennend",
  },
  {
    id: 10,
    field: "hubPress",
    headerName: "Hub Druk",
  },
  {
    id: 11,
    field: "hubTemp",
    headerName: "Hub Temp",
  },
  {
    id: 12,
    field: "hubHum",
    headerName: "Hub Hum",
  },
];
