import React from "react";
import { signOut } from "supertokens-auth-react/recipe/session";

const Logout = () => {
  async function onLogout() {
    await signOut();
    window.location.href = "/login";
  }
  onLogout();
  return <div></div>;
};

export default Logout;
