export const ForecastTableHeaders = [
  { id: 0, name: "Tijd", unit: "" },
  { id: 7, name: "", unit: "" },
  { id: 1, name: "Temperatuur", unit: "°C" },
  { id: 2, name: "Gevoel", unit: "°C" },
  { id: 8, name: "Luchtvochtigheid", unit: "%" },
  { id: 3, name: "Regenkans", unit: "%" },
  { id: 5, name: "Neerslag", unit: " mm" },
  { id: 4, name: "Wind", unit: " kph" },
  { id: 6, name: "Wolken", unit: "%" },
];
