import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import { sensorColumns } from "./consts/sensorconsts";
import { useSensorContext } from "../../api/Sensor/Sensor";

export default function SensorTable() {
  const sensorApi = useSensorContext();
  const [rows, setRows] = React.useState(null);

  if (!sensorApi.loading && rows === null) {
    setRows(Object.values(sensorApi.sensor));
  }

  return (
    <Box sx={{ height: "100%", width: "100%", display: "grid" }}>
      <Button variant="contained">
        <h4>Reload</h4>
      </Button>
      <DataGrid
        rows={rows}
        columns={sensorColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        {...(sensorApi.loading ? { loading: true } : {})}
      />
    </Box>
  );
}
