import { getSessionToken } from "@descope/react-sdk";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

export const SensorContext = createContext({});
export const useSensorContext = () => useContext(SensorContext);

// const serverURL = "http://localhost:8000";
const serverURL = "https://kobus.degraafjes.net";

export const SensorProvider = ({ children }) => {
  const [sensor, setSensor] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getSensor() {
    const sessionToken = getSessionToken();
    console.log("Getting sensor");
    await axios
      .get(serverURL + `/index.php/sensor/list`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionToken,
        },
      })
      .then((response) => {
        console.log("Sensor Received!");
        console.log(response);
        setSensor(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (sensor === null) {
      getSensor();
    }
  });

  return (
    <SensorContext.Provider
      value={{
        loading,
        sensor,
      }}
    >
      {children}
    </SensorContext.Provider>
  );
};
