const mockWeather = JSON.parse(`{
    "data": {
        "location": {
            "name": "Pijnacker",
            "region": "South Holland",
            "country": "Netherlands",
            "lat": 52.04,
            "lon": 4.43,
            "tz_id": "Europe/Amsterdam",
            "localtime_epoch": 1720530954,
            "localtime": "2024-07-09 15:15"
        },
        "current": {
            "last_updated_epoch": 1720530900,
            "last_updated": "2024-07-09 15:15",
            "temp_c": 27.1,
            "temp_f": 80.8,
            "is_day": 1,
            "condition": {
                "text": "Sunny",
                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                "code": 1000
            },
            "wind_mph": 5.6,
            "wind_kph": 9,
            "wind_degree": 140,
            "wind_dir": "SE",
            "pressure_mb": 1012,
            "pressure_in": 29.88,
            "precip_mm": 0.43,
            "precip_in": 0.02,
            "humidity": 48,
            "cloud": 0,
            "feelslike_c": 29.1,
            "feelslike_f": 84.4,
            "windchill_c": 23.4,
            "windchill_f": 74.2,
            "heatindex_c": 25.2,
            "heatindex_f": 77.4,
            "dewpoint_c": 17,
            "dewpoint_f": 62.6,
            "vis_km": 10,
            "vis_miles": 6,
            "uv": 5,
            "gust_mph": 13.4,
            "gust_kph": 21.5
        },
        "forecast": {
            "forecastday": [
                {
                    "date": "2024-07-09",
                    "date_epoch": 1720483200,
                    "day": {
                        "maxtemp_c": 25.3,
                        "maxtemp_f": 77.5,
                        "mintemp_c": 15.4,
                        "mintemp_f": 59.8,
                        "avgtemp_c": 18.9,
                        "avgtemp_f": 66,
                        "maxwind_mph": 11.6,
                        "maxwind_kph": 18.7,
                        "totalprecip_mm": 50.44,
                        "totalprecip_in": 1.99,
                        "totalsnow_cm": 0,
                        "avgvis_km": 8.5,
                        "avgvis_miles": 5,
                        "avghumidity": 78,
                        "daily_will_it_rain": 1,
                        "daily_chance_of_rain": 94,
                        "daily_will_it_snow": 0,
                        "daily_chance_of_snow": 0,
                        "condition": {
                            "text": "Heavy rain",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/308.png",
                            "code": 1195
                        },
                        "uv": 6
                    },
                    "astro": {
                        "sunrise": "05:34 AM",
                        "sunset": "10:01 PM",
                        "moonrise": "09:07 AM",
                        "moonset": "No moonset",
                        "moon_phase": "Waxing Crescent",
                        "moon_illumination": 9,
                        "is_moon_up": 0,
                        "is_sun_up": 0
                    },
                    "hour": [
                        {
                            "time_epoch": 1720476000,
                            "time": "2024-07-09 00:00",
                            "temp_c": 15.6,
                            "temp_f": 60.1,
                            "is_day": 0,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/176.png",
                                "code": 1063
                            },
                            "wind_mph": 5.4,
                            "wind_kph": 8.6,
                            "wind_degree": 88,
                            "wind_dir": "E",
                            "pressure_mb": 1018,
                            "pressure_in": 30.05,
                            "precip_mm": 0.07,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 78,
                            "cloud": 99,
                            "feelslike_c": 15.6,
                            "feelslike_f": 60.1,
                            "windchill_c": 15.6,
                            "windchill_f": 60.1,
                            "heatindex_c": 15.6,
                            "heatindex_f": 60.1,
                            "dewpoint_c": 11.9,
                            "dewpoint_f": 53.4,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 9.8,
                            "gust_kph": 15.7,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720479600,
                            "time": "2024-07-09 01:00",
                            "temp_c": 15.4,
                            "temp_f": 59.8,
                            "is_day": 0,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                                "code": 1006
                            },
                            "wind_mph": 7.8,
                            "wind_kph": 12.6,
                            "wind_degree": 84,
                            "wind_dir": "E",
                            "pressure_mb": 1017,
                            "pressure_in": 30.03,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 79,
                            "cloud": 79,
                            "feelslike_c": 15.4,
                            "feelslike_f": 59.8,
                            "windchill_c": 15.4,
                            "windchill_f": 59.8,
                            "heatindex_c": 15.4,
                            "heatindex_f": 59.8,
                            "dewpoint_c": 11.8,
                            "dewpoint_f": 53.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 14.5,
                            "gust_kph": 23.3,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720483200,
                            "time": "2024-07-09 02:00",
                            "temp_c": 15.7,
                            "temp_f": 60.2,
                            "is_day": 0,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                                "code": 1006
                            },
                            "wind_mph": 6.9,
                            "wind_kph": 11.2,
                            "wind_degree": 86,
                            "wind_dir": "E",
                            "pressure_mb": 1016,
                            "pressure_in": 30.01,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 76,
                            "cloud": 67,
                            "feelslike_c": 15.7,
                            "feelslike_f": 60.2,
                            "windchill_c": 15.7,
                            "windchill_f": 60.2,
                            "heatindex_c": 15.7,
                            "heatindex_f": 60.2,
                            "dewpoint_c": 11.4,
                            "dewpoint_f": 52.6,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 12.7,
                            "gust_kph": 20.4,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720486800,
                            "time": "2024-07-09 03:00",
                            "temp_c": 15.8,
                            "temp_f": 60.4,
                            "is_day": 0,
                            "condition": {
                                "text": "Overcast ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/122.png",
                                "code": 1009
                            },
                            "wind_mph": 6.9,
                            "wind_kph": 11.2,
                            "wind_degree": 88,
                            "wind_dir": "E",
                            "pressure_mb": 1016,
                            "pressure_in": 30,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 75,
                            "cloud": 92,
                            "feelslike_c": 15.8,
                            "feelslike_f": 60.4,
                            "windchill_c": 15.8,
                            "windchill_f": 60.4,
                            "heatindex_c": 15.8,
                            "heatindex_f": 60.4,
                            "dewpoint_c": 11.4,
                            "dewpoint_f": 52.6,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 12.6,
                            "gust_kph": 20.3,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720490400,
                            "time": "2024-07-09 04:00",
                            "temp_c": 16.1,
                            "temp_f": 60.9,
                            "is_day": 0,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/176.png",
                                "code": 1063
                            },
                            "wind_mph": 9.4,
                            "wind_kph": 15.1,
                            "wind_degree": 93,
                            "wind_dir": "E",
                            "pressure_mb": 1015,
                            "pressure_in": 29.97,
                            "precip_mm": 0.03,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 79,
                            "cloud": 97,
                            "feelslike_c": 16.1,
                            "feelslike_f": 60.9,
                            "windchill_c": 16.1,
                            "windchill_f": 60.9,
                            "heatindex_c": 16.1,
                            "heatindex_f": 60.9,
                            "dewpoint_c": 12.4,
                            "dewpoint_f": 54.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 68,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 17.2,
                            "gust_kph": 27.6,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720494000,
                            "time": "2024-07-09 05:00",
                            "temp_c": 16,
                            "temp_f": 60.9,
                            "is_day": 0,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                                "code": 1006
                            },
                            "wind_mph": 7.4,
                            "wind_kph": 11.9,
                            "wind_degree": 93,
                            "wind_dir": "E",
                            "pressure_mb": 1015,
                            "pressure_in": 29.97,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 79,
                            "cloud": 67,
                            "feelslike_c": 16,
                            "feelslike_f": 60.9,
                            "windchill_c": 16,
                            "windchill_f": 60.9,
                            "heatindex_c": 16,
                            "heatindex_f": 60.9,
                            "dewpoint_c": 12.4,
                            "dewpoint_f": 54.4,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.4,
                            "gust_kph": 21.6,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720497600,
                            "time": "2024-07-09 06:00",
                            "temp_c": 16.1,
                            "temp_f": 61,
                            "is_day": 1,
                            "condition": {
                                "text": "Overcast ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/122.png",
                                "code": 1009
                            },
                            "wind_mph": 7.2,
                            "wind_kph": 11.5,
                            "wind_degree": 103,
                            "wind_dir": "ESE",
                            "pressure_mb": 1015,
                            "pressure_in": 29.96,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 79,
                            "cloud": 100,
                            "feelslike_c": 16.1,
                            "feelslike_f": 61,
                            "windchill_c": 16.1,
                            "windchill_f": 61,
                            "heatindex_c": 16.1,
                            "heatindex_f": 61,
                            "dewpoint_c": 12.5,
                            "dewpoint_f": 54.5,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.1,
                            "gust_kph": 21,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720501200,
                            "time": "2024-07-09 07:00",
                            "temp_c": 16.3,
                            "temp_f": 61.4,
                            "is_day": 1,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/119.png",
                                "code": 1006
                            },
                            "wind_mph": 7.2,
                            "wind_kph": 11.5,
                            "wind_degree": 106,
                            "wind_dir": "ESE",
                            "pressure_mb": 1014,
                            "pressure_in": 29.96,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 79,
                            "cloud": 69,
                            "feelslike_c": 16.3,
                            "feelslike_f": 61.4,
                            "windchill_c": 16.3,
                            "windchill_f": 61.4,
                            "heatindex_c": 16.3,
                            "heatindex_f": 61.4,
                            "dewpoint_c": 12.7,
                            "dewpoint_f": 54.8,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.3,
                            "gust_kph": 21.3,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720504800,
                            "time": "2024-07-09 08:00",
                            "temp_c": 18.6,
                            "temp_f": 65.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Partly Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                                "code": 1003
                            },
                            "wind_mph": 7.8,
                            "wind_kph": 12.6,
                            "wind_degree": 130,
                            "wind_dir": "SE",
                            "pressure_mb": 1014,
                            "pressure_in": 29.95,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 72,
                            "cloud": 60,
                            "feelslike_c": 18.6,
                            "feelslike_f": 65.5,
                            "windchill_c": 18.6,
                            "windchill_f": 65.5,
                            "heatindex_c": 18.6,
                            "heatindex_f": 65.5,
                            "dewpoint_c": 13.5,
                            "dewpoint_f": 56.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 11.5,
                            "gust_kph": 18.6,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720508400,
                            "time": "2024-07-09 09:00",
                            "temp_c": 21.1,
                            "temp_f": 69.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Partly Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                                "code": 1003
                            },
                            "wind_mph": 8.5,
                            "wind_kph": 13.7,
                            "wind_degree": 137,
                            "wind_dir": "SE",
                            "pressure_mb": 1014,
                            "pressure_in": 29.94,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 65,
                            "cloud": 46,
                            "feelslike_c": 21.1,
                            "feelslike_f": 69.9,
                            "windchill_c": 21.1,
                            "windchill_f": 69.9,
                            "heatindex_c": 24.4,
                            "heatindex_f": 76,
                            "dewpoint_c": 14.2,
                            "dewpoint_f": 57.5,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 11.1,
                            "gust_kph": 17.8,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720512000,
                            "time": "2024-07-09 10:00",
                            "temp_c": 22.7,
                            "temp_f": 72.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Partly Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                                "code": 1003
                            },
                            "wind_mph": 8.9,
                            "wind_kph": 14.4,
                            "wind_degree": 143,
                            "wind_dir": "SE",
                            "pressure_mb": 1014,
                            "pressure_in": 29.93,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 61,
                            "cloud": 29,
                            "feelslike_c": 24.8,
                            "feelslike_f": 76.7,
                            "windchill_c": 22.7,
                            "windchill_f": 72.9,
                            "heatindex_c": 24.8,
                            "heatindex_f": 76.7,
                            "dewpoint_c": 14.9,
                            "dewpoint_f": 58.8,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 11.2,
                            "gust_kph": 18.1,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720515600,
                            "time": "2024-07-09 11:00",
                            "temp_c": 23.1,
                            "temp_f": 73.7,
                            "is_day": 1,
                            "condition": {
                                "text": "Partly Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                                "code": 1003
                            },
                            "wind_mph": 8.5,
                            "wind_kph": 13.7,
                            "wind_degree": 161,
                            "wind_dir": "SSE",
                            "pressure_mb": 1014,
                            "pressure_in": 29.94,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 62,
                            "cloud": 48,
                            "feelslike_c": 25,
                            "feelslike_f": 77,
                            "windchill_c": 23.1,
                            "windchill_f": 73.7,
                            "heatindex_c": 25,
                            "heatindex_f": 77,
                            "dewpoint_c": 15.4,
                            "dewpoint_f": 59.7,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 11.4,
                            "gust_kph": 18.3,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720519200,
                            "time": "2024-07-09 12:00",
                            "temp_c": 23.6,
                            "temp_f": 74.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Partly Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                                "code": 1003
                            },
                            "wind_mph": 7.2,
                            "wind_kph": 11.5,
                            "wind_degree": 169,
                            "wind_dir": "S",
                            "pressure_mb": 1014,
                            "pressure_in": 29.93,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 63,
                            "cloud": 48,
                            "feelslike_c": 25.3,
                            "feelslike_f": 77.5,
                            "windchill_c": 23.6,
                            "windchill_f": 74.5,
                            "heatindex_c": 25.3,
                            "heatindex_f": 77.5,
                            "dewpoint_c": 16.1,
                            "dewpoint_f": 60.9,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 9.8,
                            "gust_kph": 15.7,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720522800,
                            "time": "2024-07-09 13:00",
                            "temp_c": 25,
                            "temp_f": 77,
                            "is_day": 1,
                            "condition": {
                                "text": "Overcast ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/122.png",
                                "code": 1009
                            },
                            "wind_mph": 6.3,
                            "wind_kph": 10.1,
                            "wind_degree": 192,
                            "wind_dir": "SSW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.92,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 58,
                            "cloud": 90,
                            "feelslike_c": 26.2,
                            "feelslike_f": 79.1,
                            "windchill_c": 25,
                            "windchill_f": 77,
                            "heatindex_c": 26.2,
                            "heatindex_f": 79.1,
                            "dewpoint_c": 16.2,
                            "dewpoint_f": 61.2,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 7.9,
                            "gust_kph": 12.7,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720526400,
                            "time": "2024-07-09 14:00",
                            "temp_c": 25.3,
                            "temp_f": 77.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/119.png",
                                "code": 1006
                            },
                            "wind_mph": 4.5,
                            "wind_kph": 7.2,
                            "wind_degree": 216,
                            "wind_dir": "SW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.91,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 58,
                            "cloud": 84,
                            "feelslike_c": 26.4,
                            "feelslike_f": 79.5,
                            "windchill_c": 25.3,
                            "windchill_f": 77.5,
                            "heatindex_c": 26.4,
                            "heatindex_f": 79.5,
                            "dewpoint_c": 16.5,
                            "dewpoint_f": 61.6,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 5.8,
                            "gust_kph": 9.3,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720530000,
                            "time": "2024-07-09 15:00",
                            "temp_c": 27.1,
                            "temp_f": 80.8,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 5.6,
                            "wind_kph": 9,
                            "wind_degree": 140,
                            "wind_dir": "SE",
                            "pressure_mb": 1012,
                            "pressure_in": 29.88,
                            "precip_mm": 0.43,
                            "precip_in": 0.02,
                            "snow_cm": 0,
                            "humidity": 48,
                            "cloud": 0,
                            "feelslike_c": 25.2,
                            "feelslike_f": 77.4,
                            "windchill_c": 23.4,
                            "windchill_f": 74.2,
                            "heatindex_c": 25.2,
                            "heatindex_f": 77.4,
                            "dewpoint_c": 17,
                            "dewpoint_f": 62.6,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.4,
                            "gust_kph": 21.5,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720533600,
                            "time": "2024-07-09 16:00",
                            "temp_c": 18.8,
                            "temp_f": 65.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Light rain shower",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/353.png",
                                "code": 1240
                            },
                            "wind_mph": 11.6,
                            "wind_kph": 18.7,
                            "wind_degree": 310,
                            "wind_dir": "NW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.92,
                            "precip_mm": 0.21,
                            "precip_in": 0.01,
                            "snow_cm": 0,
                            "humidity": 87,
                            "cloud": 87,
                            "feelslike_c": 18.8,
                            "feelslike_f": 65.9,
                            "windchill_c": 18.8,
                            "windchill_f": 65.9,
                            "heatindex_c": 18.8,
                            "heatindex_f": 65.9,
                            "dewpoint_c": 16.5,
                            "dewpoint_f": 61.8,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 16.3,
                            "gust_kph": 26.2,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720537200,
                            "time": "2024-07-09 17:00",
                            "temp_c": 18.6,
                            "temp_f": 65.4,
                            "is_day": 1,
                            "condition": {
                                "text": "Light rain shower",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/353.png",
                                "code": 1240
                            },
                            "wind_mph": 8.7,
                            "wind_kph": 14,
                            "wind_degree": 322,
                            "wind_dir": "NW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.9,
                            "precip_mm": 0.51,
                            "precip_in": 0.02,
                            "snow_cm": 0,
                            "humidity": 89,
                            "cloud": 61,
                            "feelslike_c": 18.6,
                            "feelslike_f": 65.4,
                            "windchill_c": 18.6,
                            "windchill_f": 65.4,
                            "heatindex_c": 18.6,
                            "heatindex_f": 65.4,
                            "dewpoint_c": 16.8,
                            "dewpoint_f": 62.2,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.4,
                            "gust_kph": 21.5,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720540800,
                            "time": "2024-07-09 18:00",
                            "temp_c": 18.3,
                            "temp_f": 65,
                            "is_day": 1,
                            "condition": {
                                "text": "Light rain shower",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/353.png",
                                "code": 1240
                            },
                            "wind_mph": 8.7,
                            "wind_kph": 14,
                            "wind_degree": 0,
                            "wind_dir": "N",
                            "pressure_mb": 1011,
                            "pressure_in": 29.85,
                            "precip_mm": 2.38,
                            "precip_in": 0.09,
                            "snow_cm": 0,
                            "humidity": 92,
                            "cloud": 84,
                            "feelslike_c": 18.3,
                            "feelslike_f": 65,
                            "windchill_c": 18.3,
                            "windchill_f": 65,
                            "heatindex_c": 18.3,
                            "heatindex_f": 65,
                            "dewpoint_c": 16.9,
                            "dewpoint_f": 62.5,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.8,
                            "gust_kph": 22.2,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720544400,
                            "time": "2024-07-09 19:00",
                            "temp_c": 17.5,
                            "temp_f": 63.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Heavy rain",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/308.png",
                                "code": 1195
                            },
                            "wind_mph": 11.6,
                            "wind_kph": 18.7,
                            "wind_degree": 282,
                            "wind_dir": "WNW",
                            "pressure_mb": 1010,
                            "pressure_in": 29.83,
                            "precip_mm": 41.74,
                            "precip_in": 1.64,
                            "snow_cm": 0,
                            "humidity": 98,
                            "cloud": 100,
                            "feelslike_c": 17.5,
                            "feelslike_f": 63.5,
                            "windchill_c": 17.5,
                            "windchill_f": 63.5,
                            "heatindex_c": 17.5,
                            "heatindex_f": 63.5,
                            "dewpoint_c": 17.3,
                            "dewpoint_f": 63.1,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 5,
                            "vis_miles": 3,
                            "gust_mph": 20.4,
                            "gust_kph": 32.8,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720548000,
                            "time": "2024-07-09 20:00",
                            "temp_c": 17.6,
                            "temp_f": 63.6,
                            "is_day": 1,
                            "condition": {
                                "text": "Moderate rain at times",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/299.png",
                                "code": 1186
                            },
                            "wind_mph": 6.3,
                            "wind_kph": 10.1,
                            "wind_degree": 79,
                            "wind_dir": "E",
                            "pressure_mb": 1011,
                            "pressure_in": 29.86,
                            "precip_mm": 4.8,
                            "precip_in": 0.19,
                            "snow_cm": 0,
                            "humidity": 98,
                            "cloud": 80,
                            "feelslike_c": 17.6,
                            "feelslike_f": 63.6,
                            "windchill_c": 17.6,
                            "windchill_f": 63.6,
                            "heatindex_c": 17.6,
                            "heatindex_f": 63.6,
                            "dewpoint_c": 17.3,
                            "dewpoint_f": 63.2,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 8,
                            "vis_miles": 4,
                            "gust_mph": 11.7,
                            "gust_kph": 18.9,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720551600,
                            "time": "2024-07-09 21:00",
                            "temp_c": 18,
                            "temp_f": 64.3,
                            "is_day": 1,
                            "condition": {
                                "text": "Mist",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/143.png",
                                "code": 1030
                            },
                            "wind_mph": 5.6,
                            "wind_kph": 9,
                            "wind_degree": 101,
                            "wind_dir": "ESE",
                            "pressure_mb": 1011,
                            "pressure_in": 29.85,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 96,
                            "cloud": 33,
                            "feelslike_c": 18,
                            "feelslike_f": 64.3,
                            "windchill_c": 18,
                            "windchill_f": 64.3,
                            "heatindex_c": 18,
                            "heatindex_f": 64.3,
                            "dewpoint_c": 17.3,
                            "dewpoint_f": 63.1,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 2,
                            "vis_miles": 1,
                            "gust_mph": 9.8,
                            "gust_kph": 15.7,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720555200,
                            "time": "2024-07-09 22:00",
                            "temp_c": 17.5,
                            "temp_f": 63.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Mist",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/143.png",
                                "code": 1030
                            },
                            "wind_mph": 3.8,
                            "wind_kph": 6.1,
                            "wind_degree": 116,
                            "wind_dir": "ESE",
                            "pressure_mb": 1011,
                            "pressure_in": 29.85,
                            "precip_mm": 0.01,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 96,
                            "cloud": 82,
                            "feelslike_c": 17.5,
                            "feelslike_f": 63.5,
                            "windchill_c": 17.5,
                            "windchill_f": 63.5,
                            "heatindex_c": 17.5,
                            "heatindex_f": 63.5,
                            "dewpoint_c": 16.9,
                            "dewpoint_f": 62.4,
                            "will_it_rain": 1,
                            "chance_of_rain": 80,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 2,
                            "vis_miles": 1,
                            "gust_mph": 7,
                            "gust_kph": 11.3,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720558800,
                            "time": "2024-07-09 23:00",
                            "temp_c": 17.3,
                            "temp_f": 63.2,
                            "is_day": 0,
                            "condition": {
                                "text": "Patchy light drizzle",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/263.png",
                                "code": 1150
                            },
                            "wind_mph": 4.5,
                            "wind_kph": 7.2,
                            "wind_degree": 156,
                            "wind_dir": "SSE",
                            "pressure_mb": 1011,
                            "pressure_in": 29.86,
                            "precip_mm": 0.24,
                            "precip_in": 0.01,
                            "snow_cm": 0,
                            "humidity": 97,
                            "cloud": 66,
                            "feelslike_c": 17.4,
                            "feelslike_f": 63.2,
                            "windchill_c": 17.4,
                            "windchill_f": 63.2,
                            "heatindex_c": 17.4,
                            "heatindex_f": 63.2,
                            "dewpoint_c": 16.9,
                            "dewpoint_f": 62.4,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 5,
                            "vis_miles": 3,
                            "gust_mph": 7.8,
                            "gust_kph": 12.5,
                            "uv": 1
                        }
                    ]
                },
                {
                    "date": "2024-07-10",
                    "date_epoch": 1720569600,
                    "day": {
                        "maxtemp_c": 20.8,
                        "maxtemp_f": 69.4,
                        "mintemp_c": 16.2,
                        "mintemp_f": 61.2,
                        "avgtemp_c": 18.4,
                        "avgtemp_f": 65.1,
                        "maxwind_mph": 16.8,
                        "maxwind_kph": 27,
                        "totalprecip_mm": 2.54,
                        "totalprecip_in": 0.1,
                        "totalsnow_cm": 0,
                        "avgvis_km": 8.5,
                        "avgvis_miles": 5,
                        "avghumidity": 82,
                        "daily_will_it_rain": 1,
                        "daily_chance_of_rain": 80,
                        "daily_will_it_snow": 0,
                        "daily_chance_of_snow": 0,
                        "condition": {
                            "text": "Patchy rain nearby",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                            "code": 1063
                        },
                        "uv": 6
                    },
                    "astro": {
                        "sunrise": "05:35 AM",
                        "sunset": "10:00 PM",
                        "moonrise": "10:20 AM",
                        "moonset": "12:04 AM",
                        "moon_phase": "Waxing Crescent",
                        "moon_illumination": 16,
                        "is_moon_up": 0,
                        "is_sun_up": 0
                    },
                    "hour": [
                        {
                            "time_epoch": 1720562400,
                            "time": "2024-07-10 00:00",
                            "temp_c": 16.9,
                            "temp_f": 62.4,
                            "is_day": 0,
                            "condition": {
                                "text": "Light rain shower",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/353.png",
                                "code": 1240
                            },
                            "wind_mph": 4.9,
                            "wind_kph": 7.9,
                            "wind_degree": 179,
                            "wind_dir": "S",
                            "pressure_mb": 1012,
                            "pressure_in": 29.87,
                            "precip_mm": 0.28,
                            "precip_in": 0.01,
                            "snow_cm": 0,
                            "humidity": 98,
                            "cloud": 100,
                            "feelslike_c": 16.9,
                            "feelslike_f": 62.4,
                            "windchill_c": 16.9,
                            "windchill_f": 62.4,
                            "heatindex_c": 16.9,
                            "heatindex_f": 62.4,
                            "dewpoint_c": 16.6,
                            "dewpoint_f": 61.9,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 8.4,
                            "gust_kph": 13.6,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720566000,
                            "time": "2024-07-10 01:00",
                            "temp_c": 17.2,
                            "temp_f": 63,
                            "is_day": 0,
                            "condition": {
                                "text": "Fog",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/248.png",
                                "code": 1135
                            },
                            "wind_mph": 5.4,
                            "wind_kph": 8.6,
                            "wind_degree": 185,
                            "wind_dir": "S",
                            "pressure_mb": 1011,
                            "pressure_in": 29.87,
                            "precip_mm": 0.09,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 99,
                            "cloud": 100,
                            "feelslike_c": 17.3,
                            "feelslike_f": 63.1,
                            "windchill_c": 17.3,
                            "windchill_f": 63.1,
                            "heatindex_c": 17.3,
                            "heatindex_f": 63.1,
                            "dewpoint_c": 17,
                            "dewpoint_f": 62.6,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 0,
                            "vis_miles": 0,
                            "gust_mph": 8.5,
                            "gust_kph": 13.6,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720569600,
                            "time": "2024-07-10 02:00",
                            "temp_c": 17.5,
                            "temp_f": 63.5,
                            "is_day": 0,
                            "condition": {
                                "text": "Fog",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/248.png",
                                "code": 1135
                            },
                            "wind_mph": 7.2,
                            "wind_kph": 11.5,
                            "wind_degree": 212,
                            "wind_dir": "SSW",
                            "pressure_mb": 1011,
                            "pressure_in": 29.87,
                            "precip_mm": 0.12,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 98,
                            "cloud": 100,
                            "feelslike_c": 17.5,
                            "feelslike_f": 63.5,
                            "windchill_c": 17.5,
                            "windchill_f": 63.5,
                            "heatindex_c": 17.5,
                            "heatindex_f": 63.5,
                            "dewpoint_c": 17.2,
                            "dewpoint_f": 63,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 0,
                            "vis_miles": 0,
                            "gust_mph": 11.1,
                            "gust_kph": 17.9,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720573200,
                            "time": "2024-07-10 03:00",
                            "temp_c": 17.7,
                            "temp_f": 63.8,
                            "is_day": 0,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/176.png",
                                "code": 1063
                            },
                            "wind_mph": 11,
                            "wind_kph": 17.6,
                            "wind_degree": 242,
                            "wind_dir": "WSW",
                            "pressure_mb": 1012,
                            "pressure_in": 29.87,
                            "precip_mm": 0.13,
                            "precip_in": 0.01,
                            "snow_cm": 0,
                            "humidity": 97,
                            "cloud": 100,
                            "feelslike_c": 17.7,
                            "feelslike_f": 63.8,
                            "windchill_c": 17.7,
                            "windchill_f": 63.8,
                            "heatindex_c": 17.7,
                            "heatindex_f": 63.8,
                            "dewpoint_c": 17.2,
                            "dewpoint_f": 63,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 16.5,
                            "gust_kph": 26.5,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720576800,
                            "time": "2024-07-10 04:00",
                            "temp_c": 17.3,
                            "temp_f": 63.1,
                            "is_day": 0,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/176.png",
                                "code": 1063
                            },
                            "wind_mph": 10.1,
                            "wind_kph": 16.2,
                            "wind_degree": 244,
                            "wind_dir": "WSW",
                            "pressure_mb": 1012,
                            "pressure_in": 29.87,
                            "precip_mm": 0.01,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 97,
                            "cloud": 97,
                            "feelslike_c": 17.3,
                            "feelslike_f": 63.1,
                            "windchill_c": 17.3,
                            "windchill_f": 63.1,
                            "heatindex_c": 17.3,
                            "heatindex_f": 63.1,
                            "dewpoint_c": 16.8,
                            "dewpoint_f": 62.2,
                            "will_it_rain": 0,
                            "chance_of_rain": 63,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 15.2,
                            "gust_kph": 24.4,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720580400,
                            "time": "2024-07-10 05:00",
                            "temp_c": 16.7,
                            "temp_f": 62.1,
                            "is_day": 0,
                            "condition": {
                                "text": "Overcast ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/122.png",
                                "code": 1009
                            },
                            "wind_mph": 9.4,
                            "wind_kph": 15.1,
                            "wind_degree": 236,
                            "wind_dir": "WSW",
                            "pressure_mb": 1012,
                            "pressure_in": 29.88,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 96,
                            "cloud": 89,
                            "feelslike_c": 16.7,
                            "feelslike_f": 62.1,
                            "windchill_c": 16.7,
                            "windchill_f": 62.1,
                            "heatindex_c": 16.7,
                            "heatindex_f": 62.1,
                            "dewpoint_c": 16.2,
                            "dewpoint_f": 61.1,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 14.6,
                            "gust_kph": 23.4,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720584000,
                            "time": "2024-07-10 06:00",
                            "temp_c": 16.9,
                            "temp_f": 62.4,
                            "is_day": 1,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/119.png",
                                "code": 1006
                            },
                            "wind_mph": 9.4,
                            "wind_kph": 15.1,
                            "wind_degree": 226,
                            "wind_dir": "SW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.9,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 96,
                            "cloud": 87,
                            "feelslike_c": 16.9,
                            "feelslike_f": 62.4,
                            "windchill_c": 16.9,
                            "windchill_f": 62.4,
                            "heatindex_c": 16.9,
                            "heatindex_f": 62.4,
                            "dewpoint_c": 16.3,
                            "dewpoint_f": 61.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 14,
                            "gust_kph": 22.6,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720587600,
                            "time": "2024-07-10 07:00",
                            "temp_c": 17.5,
                            "temp_f": 63.6,
                            "is_day": 1,
                            "condition": {
                                "text": "Cloudy ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/119.png",
                                "code": 1006
                            },
                            "wind_mph": 9.6,
                            "wind_kph": 15.5,
                            "wind_degree": 218,
                            "wind_dir": "SW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.92,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 94,
                            "cloud": 69,
                            "feelslike_c": 17.6,
                            "feelslike_f": 63.6,
                            "windchill_c": 17.6,
                            "windchill_f": 63.6,
                            "heatindex_c": 17.6,
                            "heatindex_f": 63.6,
                            "dewpoint_c": 16.6,
                            "dewpoint_f": 61.8,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 13.4,
                            "gust_kph": 21.5,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720591200,
                            "time": "2024-07-10 08:00",
                            "temp_c": 18.4,
                            "temp_f": 65.2,
                            "is_day": 1,
                            "condition": {
                                "text": "Light drizzle",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/266.png",
                                "code": 1153
                            },
                            "wind_mph": 11.4,
                            "wind_kph": 18.4,
                            "wind_degree": 215,
                            "wind_dir": "SW",
                            "pressure_mb": 1013,
                            "pressure_in": 29.92,
                            "precip_mm": 0.5,
                            "precip_in": 0.02,
                            "snow_cm": 0,
                            "humidity": 91,
                            "cloud": 97,
                            "feelslike_c": 18.4,
                            "feelslike_f": 65.2,
                            "windchill_c": 18.4,
                            "windchill_f": 65.2,
                            "heatindex_c": 18.4,
                            "heatindex_f": 65.2,
                            "dewpoint_c": 17,
                            "dewpoint_f": 62.6,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 2,
                            "vis_miles": 1,
                            "gust_mph": 15,
                            "gust_kph": 24.2,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720594800,
                            "time": "2024-07-10 09:00",
                            "temp_c": 18.9,
                            "temp_f": 66,
                            "is_day": 1,
                            "condition": {
                                "text": "Light drizzle",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/266.png",
                                "code": 1153
                            },
                            "wind_mph": 12.5,
                            "wind_kph": 20.2,
                            "wind_degree": 222,
                            "wind_dir": "SW",
                            "pressure_mb": 1014,
                            "pressure_in": 29.94,
                            "precip_mm": 0.5,
                            "precip_in": 0.02,
                            "snow_cm": 0,
                            "humidity": 89,
                            "cloud": 94,
                            "feelslike_c": 18.9,
                            "feelslike_f": 66,
                            "windchill_c": 18.9,
                            "windchill_f": 66,
                            "heatindex_c": 18.9,
                            "heatindex_f": 66,
                            "dewpoint_c": 17.1,
                            "dewpoint_f": 62.8,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 2,
                            "vis_miles": 1,
                            "gust_mph": 16.6,
                            "gust_kph": 26.6,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720598400,
                            "time": "2024-07-10 10:00",
                            "temp_c": 19.2,
                            "temp_f": 66.6,
                            "is_day": 1,
                            "condition": {
                                "text": "Light rain shower",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/353.png",
                                "code": 1240
                            },
                            "wind_mph": 13.6,
                            "wind_kph": 22,
                            "wind_degree": 226,
                            "wind_dir": "SW",
                            "pressure_mb": 1014,
                            "pressure_in": 29.95,
                            "precip_mm": 0.53,
                            "precip_in": 0.02,
                            "snow_cm": 0,
                            "humidity": 88,
                            "cloud": 79,
                            "feelslike_c": 19.2,
                            "feelslike_f": 66.6,
                            "windchill_c": 19.2,
                            "windchill_f": 66.6,
                            "heatindex_c": 19.2,
                            "heatindex_f": 66.6,
                            "dewpoint_c": 17.1,
                            "dewpoint_f": 62.8,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 18.2,
                            "gust_kph": 29.3,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720602000,
                            "time": "2024-07-10 11:00",
                            "temp_c": 19.5,
                            "temp_f": 67.2,
                            "is_day": 1,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                                "code": 1063
                            },
                            "wind_mph": 14.3,
                            "wind_kph": 23,
                            "wind_degree": 238,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.96,
                            "precip_mm": 0.33,
                            "precip_in": 0.01,
                            "snow_cm": 0,
                            "humidity": 84,
                            "cloud": 83,
                            "feelslike_c": 19.5,
                            "feelslike_f": 67.2,
                            "windchill_c": 19.5,
                            "windchill_f": 67.2,
                            "heatindex_c": 19.5,
                            "heatindex_f": 67.2,
                            "dewpoint_c": 16.8,
                            "dewpoint_f": 62.3,
                            "will_it_rain": 1,
                            "chance_of_rain": 100,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 9,
                            "vis_miles": 5,
                            "gust_mph": 18.6,
                            "gust_kph": 29.9,
                            "uv": 4
                        },
                        {
                            "time_epoch": 1720605600,
                            "time": "2024-07-10 12:00",
                            "temp_c": 20.1,
                            "temp_f": 68.1,
                            "is_day": 1,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                                "code": 1063
                            },
                            "wind_mph": 15.7,
                            "wind_kph": 25.2,
                            "wind_degree": 241,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.97,
                            "precip_mm": 0.01,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 71,
                            "cloud": 88,
                            "feelslike_c": 20.1,
                            "feelslike_f": 68.1,
                            "windchill_c": 20.1,
                            "windchill_f": 68.1,
                            "heatindex_c": 20.1,
                            "heatindex_f": 68.1,
                            "dewpoint_c": 14.8,
                            "dewpoint_f": 58.6,
                            "will_it_rain": 1,
                            "chance_of_rain": 72,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 22.5,
                            "gust_kph": 36.3,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720609200,
                            "time": "2024-07-10 13:00",
                            "temp_c": 20.8,
                            "temp_f": 69.4,
                            "is_day": 1,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                                "code": 1063
                            },
                            "wind_mph": 16.6,
                            "wind_kph": 26.6,
                            "wind_degree": 243,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.98,
                            "precip_mm": 0.03,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 68,
                            "cloud": 71,
                            "feelslike_c": 20.8,
                            "feelslike_f": 69.4,
                            "windchill_c": 20.8,
                            "windchill_f": 69.4,
                            "heatindex_c": 20.8,
                            "heatindex_f": 69.4,
                            "dewpoint_c": 14.6,
                            "dewpoint_f": 58.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 68,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 20.9,
                            "gust_kph": 33.6,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720612800,
                            "time": "2024-07-10 14:00",
                            "temp_c": 20.7,
                            "temp_f": 69.3,
                            "is_day": 1,
                            "condition": {
                                "text": "Patchy rain nearby",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                                "code": 1063
                            },
                            "wind_mph": 16.3,
                            "wind_kph": 26.3,
                            "wind_degree": 244,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.98,
                            "precip_mm": 0.01,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 66,
                            "cloud": 85,
                            "feelslike_c": 20.7,
                            "feelslike_f": 69.3,
                            "windchill_c": 20.7,
                            "windchill_f": 69.3,
                            "heatindex_c": 20.7,
                            "heatindex_f": 69.3,
                            "dewpoint_c": 14.1,
                            "dewpoint_f": 57.3,
                            "will_it_rain": 1,
                            "chance_of_rain": 76,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 18.9,
                            "gust_kph": 30.5,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720616400,
                            "time": "2024-07-10 15:00",
                            "temp_c": 20.5,
                            "temp_f": 68.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 16.8,
                            "wind_kph": 27,
                            "wind_degree": 245,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.98,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 66,
                            "cloud": 10,
                            "feelslike_c": 20.5,
                            "feelslike_f": 68.9,
                            "windchill_c": 20.5,
                            "windchill_f": 68.9,
                            "heatindex_c": 20.5,
                            "heatindex_f": 68.9,
                            "dewpoint_c": 13.8,
                            "dewpoint_f": 56.9,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 19.6,
                            "gust_kph": 31.5,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720620000,
                            "time": "2024-07-10 16:00",
                            "temp_c": 20.1,
                            "temp_f": 68.2,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 16.3,
                            "wind_kph": 26.3,
                            "wind_degree": 245,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.98,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 67,
                            "cloud": 8,
                            "feelslike_c": 20.1,
                            "feelslike_f": 68.2,
                            "windchill_c": 20.1,
                            "windchill_f": 68.2,
                            "heatindex_c": 20.1,
                            "heatindex_f": 68.2,
                            "dewpoint_c": 13.8,
                            "dewpoint_f": 56.8,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 19.5,
                            "gust_kph": 31.4,
                            "uv": 6
                        },
                        {
                            "time_epoch": 1720623600,
                            "time": "2024-07-10 17:00",
                            "temp_c": 19.9,
                            "temp_f": 67.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 15.7,
                            "wind_kph": 25.2,
                            "wind_degree": 241,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.98,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 69,
                            "cloud": 13,
                            "feelslike_c": 19.9,
                            "feelslike_f": 67.9,
                            "windchill_c": 19.9,
                            "windchill_f": 67.9,
                            "heatindex_c": 19.9,
                            "heatindex_f": 67.9,
                            "dewpoint_c": 14.2,
                            "dewpoint_f": 57.5,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 19.5,
                            "gust_kph": 31.3,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720627200,
                            "time": "2024-07-10 18:00",
                            "temp_c": 19.6,
                            "temp_f": 67.2,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 14.5,
                            "wind_kph": 23.4,
                            "wind_degree": 239,
                            "wind_dir": "WSW",
                            "pressure_mb": 1015,
                            "pressure_in": 29.99,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 72,
                            "cloud": 13,
                            "feelslike_c": 19.6,
                            "feelslike_f": 67.2,
                            "windchill_c": 19.6,
                            "windchill_f": 67.2,
                            "heatindex_c": 19.6,
                            "heatindex_f": 67.2,
                            "dewpoint_c": 14.5,
                            "dewpoint_f": 58,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 18.5,
                            "gust_kph": 29.8,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720630800,
                            "time": "2024-07-10 19:00",
                            "temp_c": 18.8,
                            "temp_f": 65.9,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 13.4,
                            "wind_kph": 21.6,
                            "wind_degree": 236,
                            "wind_dir": "WSW",
                            "pressure_mb": 1016,
                            "pressure_in": 29.99,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 74,
                            "cloud": 8,
                            "feelslike_c": 18.8,
                            "feelslike_f": 65.9,
                            "windchill_c": 18.8,
                            "windchill_f": 65.9,
                            "heatindex_c": 18.8,
                            "heatindex_f": 65.9,
                            "dewpoint_c": 14.1,
                            "dewpoint_f": 57.3,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 18.7,
                            "gust_kph": 30.1,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720634400,
                            "time": "2024-07-10 20:00",
                            "temp_c": 17.8,
                            "temp_f": 64.1,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 12.5,
                            "wind_kph": 20.2,
                            "wind_degree": 233,
                            "wind_dir": "SW",
                            "pressure_mb": 1016,
                            "pressure_in": 30,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 74,
                            "cloud": 5,
                            "feelslike_c": 17.8,
                            "feelslike_f": 64.1,
                            "windchill_c": 17.8,
                            "windchill_f": 64.1,
                            "heatindex_c": 17.8,
                            "heatindex_f": 64.1,
                            "dewpoint_c": 13.2,
                            "dewpoint_f": 55.7,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 19.7,
                            "gust_kph": 31.7,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720638000,
                            "time": "2024-07-10 21:00",
                            "temp_c": 17,
                            "temp_f": 62.5,
                            "is_day": 1,
                            "condition": {
                                "text": "Sunny",
                                "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                                "code": 1000
                            },
                            "wind_mph": 11.6,
                            "wind_kph": 18.7,
                            "wind_degree": 232,
                            "wind_dir": "SW",
                            "pressure_mb": 1016,
                            "pressure_in": 30.01,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 72,
                            "cloud": 5,
                            "feelslike_c": 17,
                            "feelslike_f": 62.5,
                            "windchill_c": 17,
                            "windchill_f": 62.5,
                            "heatindex_c": 17,
                            "heatindex_f": 62.5,
                            "dewpoint_c": 11.9,
                            "dewpoint_f": 53.5,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 19.3,
                            "gust_kph": 31.1,
                            "uv": 5
                        },
                        {
                            "time_epoch": 1720641600,
                            "time": "2024-07-10 22:00",
                            "temp_c": 16.5,
                            "temp_f": 61.8,
                            "is_day": 0,
                            "condition": {
                                "text": "Clear ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/113.png",
                                "code": 1000
                            },
                            "wind_mph": 11.2,
                            "wind_kph": 18,
                            "wind_degree": 229,
                            "wind_dir": "SW",
                            "pressure_mb": 1016,
                            "pressure_in": 30.02,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 73,
                            "cloud": 15,
                            "feelslike_c": 16.6,
                            "feelslike_f": 61.8,
                            "windchill_c": 16.6,
                            "windchill_f": 61.8,
                            "heatindex_c": 16.6,
                            "heatindex_f": 61.8,
                            "dewpoint_c": 11.8,
                            "dewpoint_f": 53.2,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 18.7,
                            "gust_kph": 30.1,
                            "uv": 1
                        },
                        {
                            "time_epoch": 1720645200,
                            "time": "2024-07-10 23:00",
                            "temp_c": 16.2,
                            "temp_f": 61.2,
                            "is_day": 0,
                            "condition": {
                                "text": "Clear ",
                                "icon": "//cdn.weatherapi.com/weather/64x64/night/113.png",
                                "code": 1000
                            },
                            "wind_mph": 10.5,
                            "wind_kph": 16.9,
                            "wind_degree": 230,
                            "wind_dir": "SW",
                            "pressure_mb": 1017,
                            "pressure_in": 30.02,
                            "precip_mm": 0,
                            "precip_in": 0,
                            "snow_cm": 0,
                            "humidity": 77,
                            "cloud": 17,
                            "feelslike_c": 16.2,
                            "feelslike_f": 61.2,
                            "windchill_c": 16.2,
                            "windchill_f": 61.2,
                            "heatindex_c": 16.2,
                            "heatindex_f": 61.2,
                            "dewpoint_c": 12.2,
                            "dewpoint_f": 54,
                            "will_it_rain": 0,
                            "chance_of_rain": 0,
                            "will_it_snow": 0,
                            "chance_of_snow": 0,
                            "vis_km": 10,
                            "vis_miles": 6,
                            "gust_mph": 17.5,
                            "gust_kph": 28.2,
                            "uv": 1
                        }
                    ]
                }
            ]
        }
    },
    "status": 200,
    "statusText": "OK",
    "headers": {
        "cache-control": "public, max-age=180",
        "content-type": "application/json",
        "x-supertokens-xhr-intercepted": "true"
    },
    "config": {
        "transitional": {
            "silentJSONParsing": true,
            "forcedJSONParsing": true,
            "clarifyTimeoutError": false
        },
        "adapter": [
            "xhr",
            "http",
            "fetch"
        ],
        "transformRequest": [
            null
        ],
        "transformResponse": [
            null
        ],
        "timeout": 0,
        "xsrfCookieName": "XSRF-TOKEN",
        "xsrfHeaderName": "X-XSRF-TOKEN",
        "maxContentLength": -1,
        "maxBodyLength": -1,
        "env": {},
        "headers": {
            "Accept": "application/json, text/plain, */*"
        },
        "method": "get",
        "url": "http://api.weatherapi.com/v1/forecast.json?key=7a91bfbcf45d4dea9e3142527240107&q=52.036897, 4.428303&days=2&aqi=no&alerts=no"
    },
    "request": {
        "onload": null,
        "onreadystatechange": null
    }
}`);

export default mockWeather;
