import axios from "axios";
import mockWeather from "./tempWeather";

import { createContext, useContext, useEffect, useState } from "react";

export const WeatherContext = createContext({});
export const useWeatherContext = () => useContext(WeatherContext);

// API info
const key = "7a91bfbcf45d4dea9e3142527240107";
const lat = 52.036897;
const lon = 4.428303;
const APICall = `http://api.weatherapi.com/v1/forecast.json?key=${key}&q=${lat}, ${lon}&days=2&aqi=no&alerts=no`;

const useMockWeather = true;

export const WeatherProvider = ({ children }) => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);

  const getWeather = async () => {
    console.log("Getting weather!");
    if (useMockWeather) {
      console.log("Mock Weather used!");
      setWeather(mockWeather);
      setLoading(false);
      return;
    }

    await axios
      .get(APICall)
      .then((response) => {
        console.log("Weather Received!");
        console.log(response);
        setWeather(response);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <WeatherContext.Provider value={{ loading, weather }}>
      {children}
    </WeatherContext.Provider>
  );
};
