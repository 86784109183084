import React from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { nutritionTabs } from "./consts/nutritionconsts";
import { NutritionProvider } from "../../api/Nutrition/Nutrition";

import NutritionTable from "./NutritionTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabbedTable = () => {
  const [tabState, setTabState] = React.useState(nutritionTabs[0].id);

  const handleChange = (_event, newValue) => {
    setTabState(newValue);
  };
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabState} onChange={handleChange}>
          {nutritionTabs.map((entry) => (
            <Tab label={entry.name} key={entry.id} {...a11yProps(entry.id)} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {nutritionTabs.map((entry) => (
          <TabPanel value={tabState} index={entry.id} key={entry.id}>
            <NutritionProvider category={entry.category}>
              <NutritionTable category={entry.category} />
            </NutritionProvider>
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};

export default TabbedTable;
