import HomeIcon from "@mui/icons-material/Home";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SensorsIcon from "@mui/icons-material/Sensors";

export const mainNavbarItems = [
  {
    id: 0,
    icon: <HomeIcon />,
    label: "Home",
    route: "/",
  },
  {
    id: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#666666"
      >
        <path d="M440-760v-160h80v160h-80Zm266 110-56-56 113-114 56 57-113 113Zm54 210v-80h160v80H760Zm3 299L650-254l56-56 114 112-57 57ZM254-650 141-763l57-57 112 114-56 56Zm-14 450h180q25 0 42.5-17.5T480-260q0-25-17-42.5T421-320h-51l-20-48q-14-33-44-52.5T240-440q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T40-320q0-83 58.5-141.5T240-520q60 0 109.5 32.5T423-400q58 0 97.5 43T560-254q-2 57-42.5 95.5T420-120H240Zm320-134q-5-20-10-39t-10-39q45-19 72.5-59t27.5-89q0-66-47-113t-113-47q-60 0-105 39t-53 99q-20-5-41-9t-41-9q14-88 82.5-144T480-720q100 0 170 70t70 170q0 77-44 138.5T560-254Zm-79-226Z" />
      </svg>
    ),
    label: "Weer",
    route: "/weather",
  },
  { id: 2, icon: <FastfoodIcon />, label: "Voeding", route: "voeding" },
  { id: 3, icon: <EventNoteIcon />, label: "Dagboek", route: "dagboek" },
  { id: 4, icon: <SensorsIcon />, label: "Sensor", route: "sensor" },
];
