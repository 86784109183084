import React, { useEffect, useState } from "react";
import "./styles/styles.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

// const serverURL = "http://localhost:8000"
const serverURL = "https://kobus.degraafjes.net";

const SensorWidget = () => {
  const navigate = useNavigate();
  const [sensor, setSensor] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getLatestSensorReading() {
    console.log("Getting latest sensor");
    await axios
      .get(serverURL + `/index.php/sensor/latest`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Sensor latest Received!");
        console.log(response);
        setSensor(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getLatestSensorReading();
  }, []);

  return (
    <div onClick={() => navigate("sensor")} className="main-sensor-widget">
      <div className="sensor-widget">
        <div className="background" />
        <div className="content">
          <h2>Sensor</h2>
        </div>
        <div className="sensor-icon">
          <div className="sensor-inset">
            <div style={{ textAlign: "center" }}></div>
            <svg
              fill="#000000"
              width="170px"
              height="170px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="white"
                d="M16,2a5.608,5.608,0,0,0-4,1.523A5.608,5.608,0,0,0,8,2,5.714,5.714,0,0,0,3.609,4.077C1.776,6.276,1.643,9.8,2.518,12.358c.008.018.014.036.022.054C4.147,17,10.963,22,12,22c1.019,0,7.815-4.89,9.46-9.588.008-.018.014-.036.022-.054.863-2.517.77-6.049-1.091-8.281A5.714,5.714,0,0,0,16,2ZM12,19.818C10.609,18.914,6.739,16.2,4.982,13H9a1,1,0,0,0,.832-.445l.866-1.3,1.354,4.06a1,1,0,0,0,1.78.239L15.535,13h3.484C17.261,16.2,13.391,18.914,12,19.818ZM19.785,11H15a1,1,0,0,0-.832.445l-.866,1.3-1.354-4.06a1,1,0,0,0-1.78-.239L8.465,11H4.215c-.4-1.891-.312-4.153.931-5.643A3.67,3.67,0,0,1,8,4c3.067,0,2.747,2.1,3.989,2.112H12C13.244,6.112,12.929,4,16,4a3.67,3.67,0,0,1,2.856,1.357C20.092,6.842,20.188,9.1,19.785,11Z"
              />
            </svg>
          </div>
        </div>
        <div className="current-sensor" style={{ marginTop: "-10px" }}>
          <h2>
            <span style={{ position: "relative" }}>
              {`Laatste Update: `}
              <br />
            </span>
            {loading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <span style={{ position: "relative" }}>
                {`${sensor.date} ${sensor.time}`}
              </span>
            )}
          </h2>
        </div>

        <div className="details">
          <div className="detail-item">
            <div>
              <h4>Stappen</h4>
            </div>
            <div>
              <h2>
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  sensor.steps
                )}
              </h2>
            </div>
          </div>
          <div className="detail-item">
            <div>
              <h4>Luchtvochtigheid</h4>
            </div>
            <div>
              <h2>
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  sensor.sensHum
                )}
                <span className="light-font">%</span>
              </h2>
            </div>
          </div>
          <div className="detail-item">
            <div>
              <h4>Temperatuur</h4>
            </div>
            <div>
              <h2>
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  sensor.sensTemp
                )}
                <span className="light-font">°C</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorWidget;
