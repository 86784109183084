import Navbar from "./Components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { WeatherProvider } from "./api/Weather/Weather";

import {
  getSessionToken,
  useDescope,
  useSession,
  useUser,
} from "@descope/react-sdk";
import { Descope } from "@descope/react-sdk";

const App = () => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const sdk = useDescope();

  return (
    <>
      {!isAuthenticated && (
        <Descope
          flowId="sign-up-or-in"
          onSuccess={(e) => console.log(e.detail.user)}
          onError={(e) => console.log("Could not log in!")}
        />
      )}

      {(isSessionLoading || isUserLoading) && <p>Loading...</p>}

      {!isUserLoading && isAuthenticated && (
        <>
          <div>
            <div className="App">
              <Navbar />
              <WeatherProvider>
                <Outlet />
              </WeatherProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default App;
