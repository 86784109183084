import { SensorProvider } from "../../api/Sensor/Sensor";
import SensorTable from "../../Components/SensorTable/SensorTable";

export default function SensorPage() {
  return (
    <SensorProvider>
      <SensorTable />
    </SensorProvider>
  );
}
