import React from "react";
import "./styles/styles.scss";
import { weatherIcon } from "./icons/weather-icons.jsx";
import { useWeatherContext } from "../../api/Weather/Weather";
import { useNavigate } from "react-router-dom";

function getIcon(weatherIcon, text) {
  if (text === "Partly cloudy") {
    return weatherIcon["Cloudy"];
  } else {
    return weatherIcon[text];
  }
}

const WeatherWidget = () => {
  const hookCall = useWeatherContext();
  const weather = hookCall.loading ? null : hookCall.weather;
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("weather")}
      className="daniel-szulc-weather-widget"
    >
      {hookCall.loading && (
        <div className="loading-area">
          <div className="spinner" />
        </div>
      )}
      {!hookCall.loading && (
        <div
          className={
            "weather-widget " +
            (weather.data.current.is_day === 1 ? "" : "night-mode")
          }
        >
          <div className="background" />
          <div className="content">
            <h2>{weather.data.location.name}</h2>
          </div>
          <div className="weather-icon">
            <div className="weather-inset">
              {getIcon(weatherIcon, weather.data.current.condition.text)}
            </div>
          </div>
          <div className="current-weather">
            <h1>
              {weather.data.current.temp_c}
              <span className="light-font">°C</span>
            </h1>
            <h3>{weather.data.current.condition.text.weather_desc}</h3>
          </div>
          <div className="details">
            <div className="detail-item">
              <div>
                <h4>Wind</h4>
              </div>
              <div>
                <h2>
                  {weather.data.current.wind_kph}
                  <span className="light-font">kph</span>
                </h2>
              </div>
            </div>
            <div className="detail-item">
              <div>
                <h4>Humidity</h4>
              </div>
              <div>
                <h2>
                  {weather.data.current.humidity}
                  <span className="light-font">%</span>
                </h2>
              </div>
            </div>
            <div className="detail-item">
              <div>
                <h4>Feels Like</h4>
              </div>

              <div>
                <h2>
                  {weather.data.current.feelslike_c}
                  <span className="light-font">°C</span>
                </h2>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default WeatherWidget;
