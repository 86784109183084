export const nutritionColumns = [
  { id: 0, field: "name", headerName: "Naam", editable: true, width: "180" },
  {
    id: 1,
    field: "amount",
    headerName: "Hoeveelheid",
    editable: true,
    width: "180",
  },
];

export const nutritionCategories = [
  "ochtend",
  "slobber",
  "supplementen",
  "medicijnen",
];

export const nutritionTabs = [
  {
    id: 0,
    name: "Ochtend",
    category: nutritionCategories[0],
  },
  { id: 1, name: "Slobber", category: nutritionCategories[1] },
  {
    id: 2,
    name: "Supplementen",
    category: nutritionCategories[2],
  },
  {
    id: 3,
    name: "Medicijnen",
    category: nutritionCategories[3],
  },
];
