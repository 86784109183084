import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";
import WeatherWidget from "../../Components/WeatherWidget/WeatherWidget";
import DiaryWidget from "../../Components/DiaryWidget/DiaryWidget";
import FoodWidget from "../../Components/FoodWidget/FoodWidget";
import SensorWidget from "../../Components/SensorWidget/SensorWidget";
import { SensorProvider } from "../../api/Sensor/Sensor";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid
        container
        spacing={{ xs: 0.5 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid display="flex" justifyContent="center" alignItems="flex-start">
          <Item>
            <WeatherWidget />
          </Item>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="flex-start">
          <Item>
            <DiaryWidget />
          </Item>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="flex-start">
          <Item>
            <SensorWidget />
          </Item>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="flex-start">
          <Item>
            <FoodWidget />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
