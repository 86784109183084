import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  DragAndDrop,
  Resize,
} from "@syncfusion/ej2-react-schedule";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "./styles/calendar.scss";
import { useState, useRef } from "react";
import Slider from "@mui/material/Slider";
import { isNullOrUndefined } from "@syncfusion/ej2-base";

// const serverURL = "http://localhost:8000";
const serverURL = "https://kobus.degraafjes.net";

const CalendarApp = () => {
  const scheduleObj = useRef(null);
  const eventTypes = [null, "Training", "Vitaliteit", "Afspraak"];
  const [eventTypeState, setEventTypeState] = useState(null);
  const [eventData, setEventData] = useState(null);
  const dataManager = new DataManager({
    adaptor: new UrlAdaptor(),
    url: serverURL + "/index.php/diary/list",
    crudUrl: serverURL + "/index.php/diary/update",
  });

  const onStateChange = (args) => {
    if (args.e !== null && args.value !== null) {
      setEventTypeState(args.value);
    }
  };

  const editorTemplate = (props) => {
    if (props !== undefined) {
      return (
        <table className="custom-event-editor">
          <tbody>
            <tr>
              <td>Type: </td>
              <td colSpan={4}>
                <DropDownListComponent
                  style={{ align: "center" }}
                  name="type"
                  id="type"
                  className="e-field"
                  dataSource={eventTypes}
                  change={onStateChange}
                  value={eventTypeState}
                  placeholder="Select het type"
                />
              </td>
            </tr>
            {eventTypeState === "Vitaliteit" && (
              <>
                <tr>
                  <td className="e-textlabel">Titel: </td>
                  <td colSpan={4}>
                    <input
                      id="subject"
                      name="subject"
                      className="e-field e-input"
                      type="text"
                      defaultValue={"Meting"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">Moment: </td>
                  <td colSpan={4}>
                    <DateTimePickerComponent
                      format="dd/MM/yy hh:mm a"
                      id="startTime"
                      data-name="startTime"
                      className="e-field"
                      defaultValue={eventData.startTime}
                    ></DateTimePickerComponent>
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel" style={{ textAlign: "center" }} />
                  <td
                    className="e-textlabel"
                    style={{ textAlign: "center", width: "40%" }}
                  >
                    Temperatuur
                  </td>
                  <td className="e-textlabel" style={{ textAlign: "center" }}>
                    Pulsatie
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">LV: </td>
                  <td>
                    <input
                      id="lv_temp"
                      className="e-field e-input"
                      type="number"
                      name="lv_temp"
                    />
                  </td>
                  <td>
                    <Slider
                      id="lv_pulse"
                      aria-label="lv_pulse"
                      defaultValue={eventData.lv_pulse}
                      valueLabelDisplay="auto"
                      step={0.5}
                      marks
                      min={0}
                      max={3}
                      size="small"
                      onChange={(e, val) => {
                        eventData.lv_pulse = val;
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">RV: </td>
                  <td colSpan={1}>
                    <input
                      id="rv_temp"
                      className="e-field e-input"
                      type="number"
                      name="rv_temp"
                    />
                  </td>
                  <td colSpan={1}>
                    <Slider
                      id="rv_pulse"
                      aria-label="rv_pulse"
                      defaultValue={eventData.rv_pulse}
                      valueLabelDisplay="auto"
                      step={0.5}
                      marks
                      min={0}
                      max={3}
                      size="small"
                      onChange={(e, val) => {
                        eventData.rv_pulse = val;
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">LA: </td>
                  <td colSpan={1}>
                    <input
                      id="la_temp"
                      className="e-field e-input"
                      type="number"
                      name="la_temp"
                    />
                  </td>
                  <td>
                    <Slider
                      id="la_pulse"
                      aria-label="la_pulse"
                      defaultValue={eventData.la_pulse}
                      valueLabelDisplay="auto"
                      step={0.5}
                      marks
                      min={0}
                      max={3}
                      size="small"
                      onChange={(e, val) => {
                        eventData.la_pulse = val;
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel" colSpan={1}>
                    {" "}
                    RA:{" "}
                  </td>
                  <td colSpan={1}>
                    <input
                      id="ra_temp"
                      className="e-field e-input"
                      type="number"
                      name="ra_temp"
                    />
                  </td>
                  <td>
                    <Slider
                      id="ra_pulse"
                      aria-label="ra_pulse"
                      defaultValue={eventData.ra_pulse}
                      valueLabelDisplay="auto"
                      step={0.5}
                      marks
                      min={0}
                      max={3}
                      size="small"
                      onChange={(e, val) => {
                        eventData.ra_pulse = val;
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Lichaamstemperatuur: </td>
                  <td colSpan={2}>
                    <input
                      id="body_temp"
                      className="e-field e-input"
                      type="number"
                      name="body_temp"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">Mentale Gesteldheid: </td>
                  <td colSpan={4}>
                    <textarea
                      id="mentaal"
                      className="e-field e-input"
                      name="mentaal"
                      rows={3}
                      cols={50}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td className="e-textlabel">Overig: </td>
                  <td colSpan={4}>
                    <textarea
                      id="overig"
                      className="e-field e-input"
                      name="overig"
                      rows={3}
                      cols={50}
                    ></textarea>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      );
    }
  };

  const fieldNames = {
    id: "id",
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
    subject: { name: "subject" },
    isAllDay: { name: "isAllDay" },
    recurrenceRule: { name: "recurrenceRule" },
    recurrenceID: { name: "recurrenceID" },
    recurrenceException: { name: "recurrenceException" },
    location: { name: "location" },
    description: { name: "description" },
  };

  const onPopupOpen = (args) => {
    if (args.type === "QuickInfo") {
      if (isNullOrUndefined(args.data)) {
        setEventTypeState(null);
        setEventData(null);
      } else {
        setEventTypeState(args.data.type);
        setEventData(args.data);
      }
    }
  };

  const onPopupClose = (args) => {
    if (args.type === "Editor") {
      if (eventTypeState === "Vitaliteit" && !isNullOrUndefined(args.data)) {
        // Copy over the sliders
        args.data.lv_pulse = eventData.lv_pulse;
        args.data.rv_pulse = eventData.rv_pulse;
        args.data.la_pulse = eventData.la_pulse;
        args.data.ra_pulse = eventData.ra_pulse;
      }
    }
  };

  return (
    <ScheduleComponent
      ref={scheduleObj}
      eventSettings={{ fields: fieldNames, dataSource: dataManager }}
      editorTemplate={editorTemplate}
      popupOpen={onPopupOpen}
      popupClose={onPopupClose}
    >
      <Inject
        services={[Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]}
      />
    </ScheduleComponent>
  );
};
export default CalendarApp;
